import { Box, Button, Link, Text } from "@chakra-ui/react";
import { AdministratorModel, RateSettingModel } from "@obtainly-v2/schema";
import { ContentLoader } from "components/common";
import { Table } from "components/common/Table";
import { routes } from "config/routes";
import { useTableSort } from "hooks";
import NextLink from "next/link";
import { FC, useEffect, useMemo } from "react";
import { RiCheckFill, RiCloseFill } from "react-icons/ri";
import { useQuery } from "react-query";
import { Column } from "react-table";
import { rateSettingService } from "services";
import { formatCurrency } from "utils";

interface RateSettingsTableProps {
  admin?: AdministratorModel;
  hiddenColumns?: (
    | "createdAt"
    | "interestRate"
    | "penaltyRate"
    | "maximumAmount"
    | "minimumAmount"
    | "duration"
    | "systemRate"
  )[];
}

export const RateSettingsTable: FC<RateSettingsTableProps> = ({
  hiddenColumns,
}) => {
  const { tableQueryProps, updateTableQueryProps, initializeSorting } =
    useTableSort({
      page: 1,
      sort: { id: "duration", desc: false },
    });

  const { data: rateSettings, isLoading } = useQuery(
    "rate_setting_read_all",
    rateSettingService.readAll
  );

  const columns = useMemo(() => {
    const defaultColumns: Column<RateSettingModel>[] = [
      {
        id: "duration",
        Header: "Duration",
        accessor: "duration",
        Cell: ({ cell: { value } }) => <Text textAlign="center">{value}</Text>,
      },
      {
        id: "interestRate",
        Header: "Interest Rate",
        accessor: "interestRate",
        Cell: ({ cell: { value } }) => <Text textAlign="center">{value}</Text>,
      },
      {
        id: "penaltyRate",
        Header: "Penalty Rate",
        accessor: "penaltyRate",
        Cell: ({ cell: { value } }) => <Text textAlign="center">{value}</Text>,
      },
      {
        id: "systemRate",
        Header: "Default rate",
        accessor: "systemRate",
        Cell: ({ cell }) => (
          <Text color={cell.value ? "success.600" : "critical.600"}>
            {cell.value ? <RiCheckFill /> : <RiCloseFill />}
          </Text>
        ),
      },
      {
        id: "minimumAmount",
        Header: "Minimum Amount",
        accessor: "minimumAmount",
        Cell: ({ cell: { value } }) => (
          <Text textAlign="right">
            {value ? formatCurrency(value, { decimals: 0 }) : "-"}
          </Text>
        ),
      },
      {
        id: "maximumAmount",
        Header: "Maximum Amount",
        accessor: "maximumAmount",
        Cell: ({ cell: { value } }) => (
          <Text textAlign="right">
            {value ? formatCurrency(value, { decimals: 0 }) : "-"}
          </Text>
        ),
      },
      {
        id: "actions",
        Header: "Action",
        Cell: ({ row }: any) => (
          <NextLink
            href={`${routes.admin.rateSettings}/${row.original?._id}`}
            passHref
          >
            <Link>
              <Button size="sm">View</Button>
            </Link>
          </NextLink>
        ),
      },
    ];

    if (hiddenColumns?.length) {
      return defaultColumns.filter(
        (column: any) => !hiddenColumns.includes(column.id)
      );
    }
    return defaultColumns;
  }, [hiddenColumns]);

  useEffect(() => {
    if (rateSettings?.length) {
      initializeSorting();
    }
  }, [rateSettings, initializeSorting]);
  return (
    <Box overflowX="auto">
      <ContentLoader
        isLoading={isLoading}
        contentUnavailable={!rateSettings?.length}
        errorMessage="No rate setting found"
      />
      {!!rateSettings?.length && (
        <Table
          columns={columns}
          data={rateSettings}
          queryProps={{
            ...tableQueryProps,
            onQueryChange: updateTableQueryProps,
          }}
        />
      )}
    </Box>
  );
};
