import { Button, Checkbox, SimpleGrid, VStack } from "@chakra-ui/react";
import { BusinessType } from "@obtainly-v2/enums";
import { RateSettingModel } from "@obtainly-v2/schema";
import { BusinessAutocomplete } from "components/business";
import { FormBaseProps, FormField, FormGroup } from "components/common";
import { useFormWithSchema, useToast } from "hooks";
import React from "react";
import { Controller } from "react-hook-form";
import { useMutation } from "react-query";
import { rateSettingService } from "services";
import * as Yup from "yup";

const YupNumberSchema = () => {
  return Yup.number()
    .min(Number.MIN_VALUE, ({ label }) => `${label} must be greater than zero`)
    .typeError(({ label }) => `${label} must be a number`);
};

const YupOptionalNumberSchema = () => {
  return Yup.number()
    .min(0, ({ label }) => `${label} must be greater than zero`)
    .transform((value) => {
      if (!isNaN(value) && value <= 0) return undefined;
      return value;
    })
    .typeError(({ label }) => `${label} must be a number`);
};

const rateSettingFormSchema = Yup.object({
  interestRate: YupNumberSchema().required().label("Interest rate"),
  penaltyRate: Yup.number()
    .typeError(({ label }) => `${label} must be a number`)
    .required()
    .label("Penalty rate"),
  systemRate: Yup.boolean().required().label("System rate"),
  duration: YupNumberSchema().required().label("Duration"),
  minimumAmount: YupOptionalNumberSchema().optional().label("Minimum amount"),
  maximumAmount: YupOptionalNumberSchema().optional().label("Maximum amount"),
  businessId: Yup.string().when("systemRate", {
    is: false,
    then: Yup.string().required(),
    otherwise: Yup.string().optional(),
  }),
});

export type RateSettingFormInputType = Yup.InferType<
  typeof rateSettingFormSchema
>;

interface RateSettingFormProps
  extends FormBaseProps<RateSettingFormInputType, RateSettingModel> {
  rateSetting?: RateSettingModel;
}

export const RateSettingForm: React.FC<RateSettingFormProps> = ({
  rateSetting,
  onSubmit,
  onSuccess,
  onError,
}) => {
  const { toast } = useToast();
  const defaultValues: RateSettingFormInputType = {
    interestRate: rateSetting?.interestRate || 0,
    penaltyRate: rateSetting?.penaltyRate || 0,
    systemRate: rateSetting?.systemRate || false,
    duration: rateSetting?.duration || 0,
    minimumAmount: rateSetting?.minimumAmount || 0,
    maximumAmount: rateSetting?.maximumAmount || 0,
    businessId: rateSetting?.businessId || "",
  };
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset: resetForm,
    watch,
  } = useFormWithSchema(rateSettingFormSchema, {
    defaultValues,
    mode: "all",
  });

  const [systemRate] = watch(["systemRate"]);

  const { mutate: mutateAdminCreate, isLoading: isCreatingLoanRequest } =
    useMutation(rateSettingService.create);
  const { mutate: mutateAdminUpdate, isLoading: isUpdatingLoanRequest } =
    useMutation(rateSettingService.update);

  const [isLoading, setLoading] = React.useState(false);

  const isSubmittingForm = React.useMemo(() => {
    return isCreatingLoanRequest || isUpdatingLoanRequest || isLoading;
  }, [isCreatingLoanRequest, isUpdatingLoanRequest, isLoading]);

  const errorHandler = (error: any) => {
    const message = error?.response?.data?.message || "Unknown error occurred";
    onError?.(new Error(message));
    toast({
      description: message,
      status: "error",
    });
  };

  const successHandler = (
    formValues: RateSettingFormInputType,
    data: RateSettingModel,
    toastMessage: string
  ) => {
    onSuccess?.(data);
    resetForm(formValues);
    toast({
      description: toastMessage,
      status: "success",
    });
  };

  const commitChanges = (values: RateSettingFormInputType) => {
    if (!!rateSetting) {
      const payload = { ...rateSetting, ...values };
      mutateAdminUpdate(payload, {
        onSuccess: () => successHandler(values, payload, "Updated"),
        onError: errorHandler,
      });
    } else {
      mutateAdminCreate(
        { ...values },
        {
          onSuccess: ({ data }) =>
            successHandler(defaultValues, data, "Created"),
          onError: errorHandler,
        }
      );
    }
  };

  const onSubmitForm = async (values: RateSettingFormInputType) => {
    if (onSubmit) {
      setLoading(true);
      onSubmit(values)
        .then((newValues) => {
          resetForm(newValues);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      // console.log(values);
      // return;
      commitChanges(values);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <VStack spacing="20px" alignItems="stretch">
          <FormGroup label="System Rate">
            <Controller
              name="systemRate"
              control={control}
              render={({
                field: { ref, value, ...rest },
                fieldState: { error },
              }) => (
                <Checkbox {...rest} isChecked={value} alignItems="start">
                  Set as default (system rate)
                </Checkbox>
              )}
            />
          </FormGroup>

          {!systemRate && (
            <FormGroup label="Vendor">
              <Controller
                name="businessId"
                control={control}
                render={({
                  field: { ref, onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <BusinessAutocomplete
                    businessType={BusinessType.Vendor}
                    onChange={(data) => {
                      onChange({ target: data });
                    }}
                    errorMessage={error?.message}
                    {...rest}
                  />
                )}
              />
            </FormGroup>
          )}

          <FormGroup label="Duration (in days)">
            <FormField
              type="number"
              autoComplete="rate-duration"
              placeholder="Duration"
              errorMessage={errors.duration?.message}
              {...register("duration")}
            />
          </FormGroup>

          <SimpleGrid templateColumns="1fr 1fr" gap={3}>
            <FormGroup
              label="Interest Rate"
              helperText="Effective interest rate over specified duration"
            >
              <FormField
                autoComplete="rate-interest"
                placeholder="Interest Rate"
                errorMessage={errors.interestRate?.message}
                {...register("interestRate")}
              />
            </FormGroup>
            <FormGroup
              label="Penalty Rate"
              helperText="Late payment penalty rate charged per day"
            >
              <FormField
                autoComplete="rate-penalty"
                placeholder="Penalty Rate"
                errorMessage={errors.penaltyRate?.message}
                {...register("penaltyRate")}
              />
            </FormGroup>
          </SimpleGrid>

          <SimpleGrid templateColumns="1fr 1fr" gap={3}>
            <FormGroup label="Minimum Amount">
              <FormField
                autoComplete="rate-minimum-amount"
                placeholder="Minimum Amount"
                errorMessage={errors.minimumAmount?.message}
                {...register("minimumAmount")}
              />
            </FormGroup>
            <FormGroup label="Maximum Amount">
              <FormField
                autoComplete="rate-maximum-amount"
                placeholder="Maximum Amount"
                errorMessage={errors.maximumAmount?.message}
                {...register("maximumAmount")}
              />
            </FormGroup>
          </SimpleGrid>

          <Button
            isLoading={isSubmittingForm}
            isDisabled={isSubmittingForm || !isValid || !isDirty}
            type="submit"
            data-test="submit-rate-setting"
            colorScheme="primary"
          >
            {!rateSetting ? "Create" : "Update"}
          </Button>
          {isDirty && (
            <Button
              isDisabled={isSubmittingForm}
              type="submit"
              data-test="submit-login"
              onClick={() => resetForm(defaultValues)}
            >
              Discard Changes
            </Button>
          )}
        </VStack>
      </form>
    </>
  );
};
