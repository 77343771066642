import { Text } from "@chakra-ui/react";
import { Autocomplete, AutocompleteProps } from "components/common";
import React, { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import { rateSettingService } from "services";

export interface RateDurationAutocompleteProps
  extends Omit<AutocompleteProps, "onChange"> {
  vendorId: string;
  onChange: (value: number) => void;
  errorMessage?: string;
}

export const RateDurationAutocomplete: React.FC<
  RateDurationAutocompleteProps
> = ({ vendorId, isDisabled, errorMessage, onChange, ...rest }) => {
  const { data: durations, isLoading: isReadingAll } = useQuery(
    `rate_setting_available_durations_${vendorId}`,
    () => rateSettingService.readAvailableDurations(vendorId),
    { enabled: !!vendorId }
  );

  const options = useMemo(() => {
    return (
      durations
        ?.sort((a, b) => a - b)
        .map((duration) => ({
          label: `${duration} days`,
          value: duration,
        })) || []
    );
  }, [durations]);

  const handleChange = useCallback(
    (selectedOption: any) => {
      const selectedDuration = durations?.find(
        (duration) => duration === selectedOption?.value
      );
      !!selectedDuration && onChange(selectedDuration);
    },
    [durations, onChange]
  );

  return (
    <>
      <Autocomplete
        options={options}
        isDisabled={isReadingAll || !!isDisabled}
        onChange={handleChange}
        {...rest}
      />
      {!!errorMessage && (
        <Text fontSize="sm" color="red.500" mt="2px">
          {errorMessage}
        </Text>
      )}
    </>
  );
};
